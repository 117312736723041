<template>
  <ul class="footer__socials">
    <li
      v-for="(social, socialIndex) in SOCIALS"
      :key="socialIndex"
    >
      <BaseLink
        class="footer__social"
        :href="social.href"
      >
        <BaseIcon2
          :id="social.icon"
          class="footer__social-icon"
          height="32"
          sprite="socials"
          width="32"
        />
      </BaseLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>
// TODO: Ссылки пока пустые. Добавить когда появятся соц.сети
const SOCIALS = [
  {
    href: "/",
    icon: "icon-whatsapp-flat",
  },
  {
    href: "/",
    icon: "icon-telegram-flat",
  },
  {
    href: "/",
    icon: "icon-instagram-flat",
  },
];
</script>

<style scoped>
.footer__socials {
  display: flex;
  column-gap: 19px;
  color: var(--white);
}

.footer__social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--white);
  border-radius: 50%;
  background-color: var(--grey-3);
}

@media (--hover) {
  .footer__social:hover {
    background-color: var(--grey-2);
  }
}

.footer__social-icon {
  flex-shrink: 0;
}
</style>
